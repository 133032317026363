export default {
  data: function () {
    return {
      mobileLoginSuccessPath: '/mobile-app/login-success/'
    }
  },
  methods: {
    googleSignIn () {
      window.jda.pageView('/google')
      let path = this.$router.currentRoute.path
      this.$_.delay(() => { window.location = '/api/oauth/google?path=' + encodeURIComponent(path) }, 1000)
    },
    microsoftSignIn () {
      window.jda.pageView('/microsoft')
      let path = this.$router.currentRoute.path
      this.$_.delay(() => { window.location = '/api/oauth/ms?path=' + encodeURIComponent(path) }, 1000)
    },
    googleSignInForMobile () {
      window.jda.pageView('/google')
      let path = this.mobileLoginSuccessPath + this.$route.params.token
      this.$_.delay(() => { window.location = '/api/oauth/google?path=' + encodeURIComponent(path) }, 1000)
    },
    microsoftSignInForMobile () {
      window.jda.pageView('/microsoft')
      let path = this.mobileLoginSuccessPath + this.$route.params.token
      this.$_.delay(() => { window.location = '/api/oauth/ms?path=' + encodeURIComponent(path) }, 1000)
    }
  }
}
